<template>

    <v-layout row wrap>
        <v-flex xs12>

            <v-simple-table>

                <thead>
                    <tr>
                        <th v-for="head in headers">{{head.text}}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="item in listEvents">
                        <td class="text-xs-left table-span" >{{ item.id }}</td>
                        <td class="text-xs-left table-span" >{{Util.UtilDate.toStringDatePretty(item.dateFrom,true)}} - {{Util.UtilDate.toStringDatePretty(item.dateTo,true)}}</td>
                        <td class="text-xs-left table-span" >{{item.title }}</td>
                        <td class="text-xs-left table-span " >{{item.category + '/' + item.categorySub}}</td>
                    </tr>
                </tbody>

            </v-simple-table>


            <v-snackbar v-model="snackDisplay" :timeout="3000" :color="snackColor">
                {{ snackText }}
                <v-btn flat @click="snackDisplay = false">Close</v-btn>
            </v-snackbar>


        </v-flex>
        <v-flex xs12>
            <v-btn @click="onSaveInDatabase">Save</v-btn>
            <v-btn @click="onRefresh">Refresh</v-btn>
        </v-flex>
    </v-layout>

</template>


<script>
    import {ComponentBasic} from "@/utilities/UtilitiesVue";
    import {RestApiApergia} from "@/utilities/RestClientApergia";
    import {PayloadStructure} from "@/model/basic/ModelBasic";
    import {UtilitiesApp, UtilDate} from "@/utilities/UtilitiesApp";

    export default {
        mixins: [ComponentBasic],
        data() {
            return {
                LN: "[PageAdminEvents]::",
                listEvents: [],
                listSelected: [],
                payload: PayloadStructure.loading(Language.pleaseWait),
                max25chars: v => v.length <= 25 || 'Input too long!',
                snackText: '',
                snackColor: '',
                snackDisplay: false,
                mapEventsToSave: new Map(),
                pagination: {
                    rowsPerPage: 15, // -1 for All
                    sortBy: 'from'
                },
                headers: [
                    {text: 'Id', align: 'left', value: 'id'},
                    {text: 'From', value: 'from'},
                    {text: 'Title', value: 'title'},
                    {text: 'SubCategory', value: 'subCategory'},
                ]
            }
        },
        created() {
            this.onRefresh();
        },
        methods: {
            onSaveSample() {
                let formData = new FormData();
                formData.set('token', this.getToken());
                formData.set('type', this.fieldType);
                formData.set('content', this.fieldContent);
                formData.set('properties', JSON.stringify({
                    'data': this.fieldData,
                    'show_common_bar': this.fieldSideBar
                }));

                RestClientApergia.adminSaveContent(
                    val => {
                        this.EventThrow('notify', "Saved");
                        this.LoadSettings();
                    },
                    val => {
                        this.EventThrow('notify', "Failed !!!");
                    },
                    formData);
            },

            onRefresh() {
                this.mapEventsToSave = new Map();
                this.spanResultSummary = "";
                this.payload = PayloadStructure.loading(Language.pleaseWait);
                this.dateFrom = null;
                this.dateTo = null;

                this.Log("Period [" + UtilDate.toStringDatePretty(this.dateFrom) + "-" + UtilDate.toStringDatePretty(this.dateTo) + "]");

                this.GetEventSearchPromise(this.dateFrom, this.dateTo, this.categoryItem == null ? null : this.categoryItem.value, this.textFilter, true)
                    .then((eventWrapper) => {
                        this.listEvents = eventWrapper.listEvents.sort((a, b) => a.dateFrom > b.dateFrom ? -1 : 1);

                        this.payload = PayloadStructure.info(eventWrapper);
                        if (eventWrapper.totalCount != null)
                            this.spanResultSummary = "Σύνολο " + eventWrapper.totalCount;
                    }).catch((error) => {
                        this.payload = PayloadStructure.error(Language.error)
                    }
                );
            },

            onSaveInDatabase() {
                this.Log("Save", JSON.stringify(this.mapEventsToSave.values()));
                let formData = new FormData();
                formData.set('token', this.getToken());
                formData.set('events', JSON.stringify(this.mapEventsToSave.values()));
                RestClientApergia.adminSaveEvents(
                    val => {
                        this.EventThrow('notify', "Saved");
                        this.LoadSettings();
                    },
                    val => {
                        this.EventThrow('notify', "Failed !!!");
                    },
                    formData);
            },

            filterEvents() {
                return this.listEvents;
            },

            onSave(event) {
                this.mapEventsToSave.set(event.id, event);
                this.showMiniSnack(true, 'success', 'Data to be saved');
            },
            onCancel() {
                this.showMiniSnack(true, 'error', 'Canceled');
            },
            onOpen() {
                this.showMiniSnack(true, 'info', 'Dialog opened');
            },
            onClose() {
                this.Log('Dialog closed');
            },
            showMiniSnack(show, color, text) {
                this.snackDisplay = show;
                this.snackColor = color;
                this.snackText = text
            }
        },
        watch: {
            /*On Change content/settings from server*/
            getContentMap(val) {
                this.setUpContentTypes('error/refresh');
            },
            /*On Change user selection (content)*/
            fieldContent(val) {
                this.createPreview();
            }
        }
    }
</script>

<style scoped>
    .table-span {
        font-size: 12px
    }
</style>
